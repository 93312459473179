































import Vue from 'vue';
import Swiper from 'swiper';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Component
export default class Horizontal extends Vue {
  @Prop({
    required: true
  })
  public data!: any;

  public swiper: any = null;

  public mounted() {
    this.activeSwiper();
  }

  public activeSwiper() {
    this.swiper = new Swiper('.swiper-container', {
      slidesPerView: 'auto',
      spaceBetween: 15
    });
  }
}
