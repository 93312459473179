







































































































































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import Swiper from 'swiper';
import 'swiper/css/swiper.css'
import { MainBanner } from '@/store/banner/types';
import { mixins } from 'vue-class-component';
import DataFormat from '@/shared/utils/dataFormat';
import { bannerModule } from '@/store';
import * as firebase from 'firebase/app';

@Component
export default class BannerList extends mixins(DataFormat) {
  @Prop({
    required: true
  })
  public banner!: MainBanner;

  @Prop()
  public visitedList!: any;

  @Prop()
  public area!: 8179;

  @Prop()
  public index!: any;

  @Prop()
  public myLocation!: any;
  @Prop()
  public nowLocation!: any;

  public swiper: any = null;
  public currentPosition = 1;
  public totalCount = this.banner ? this.banner.banner.length : 0;

  // @Watch('area')
  // public areaChangeReset() {
  //   // this.swiper.slideTo(0);
  // }
  // @Watch('myLocation')
  // public myLocationReset() {
  //   // this.swiper.slideTo(0);
  //   window.scrollTo(0, 0);
  // }

  @Watch('nowLocation')
  public nowLocationUpdate() {
    this.swiper.slideTo(0);
    window.scrollTo(0, 0);
  }

  public mounted() {
    this.activeSwiper();
  }

  public activeSwiper() {
    if (this.swiper) {
      this.swiper.destroy();
      this.swiper = null;
    }
    if (this.banner.type === 'slide') {
      this.swiper = new Swiper('.banner-list .slide' + this.index, {
        slidesPerView: 'auto',
        loop: true,
        centeredSlides: true,
        observer: true,
        observeParents: true,
        pagination: {
          el: '.swiper-pagination',
          type: 'progressbar',
        },
      });
      this.swiper.on('slideChange', () => {
        this.currentPosition = this.swiper.realIndex + 1;
      })
    } else if (this.banner.type === 'static') {
      if (this.banner.meta.shape === 'rectangle_image') {
        if (this.banner.banner.length > 1) {
          this.swiper = new Swiper('.banner-list .static' + this.index, {
            slidesPerView: 'auto',
            loop: true,
            observer: true,
            observeParents: true,
            pagination: {
              el: '.swiper-pagination',
              type: 'bullets',
            }
          });
        }
      } else {
        this.swiper = new Swiper('.banner-list .static' + this.index, {
          slidesPerView: 'auto',
          observer: true,
          observeParents: true,
        });
      }
    } else if (
        this.banner.type === 'place' ||
        this.banner.type === 'product' ||
        this.banner.type === 'list'
      ) {
      this.swiper = new Swiper(`.banner-list .place${this.index}, .banner-list .product${this.index}, .banner-list .list${this.index}`, {
        slidesPerView: 'auto',
        freeMode: true,
        observer: true,
        observeParents: true,
      });
    }
  }

  public setAreaName(area) {
    const name = area.split(' ');
    return name[1];
  }

  public setDistance(data) {
    if (1 < data.kilometer) return (data.kilometer).toFixed(1) + 'km';
    else return (data.meter).toFixed(0) + 'm';
  }

  public eventSelectBanner(index) {
    const eventName = 'select_banner';
    const eventParams: any = {
      area_id: Number(this.area),
      group_type: this.banner.type,
      group_sub_type: this.banner.sub_type,
      group_id: Number(this.banner.id),
      group_name: this.banner.meta.service_title,
      group_order: Number(this.index),
      type: null,
      id: null,
      name: null,
      order: Number(index)
    }

    if (this.banner.banner.length) {
      eventParams.type = 'banner';
      eventParams.id = Number(this.banner.banner[index].id);
      eventParams.name = this.banner.banner[index].title;
    } else if (this.banner.place.length) {
      eventParams.type = 'place';
      eventParams.id = Number(this.banner.place[index].id);
      eventParams.name = this.banner.place[index].name;
    } else if (this.banner.sub_type === 'interest') {
      eventParams.type = 'place';
      eventParams.id = Number(this.visitedList[index].id);
      eventParams.name = this.visitedList[index].name;
    } else if (this.banner.product.length) {
      eventParams.type = 'product';
      eventParams.id = Number(this.banner.product[index].id);
      eventParams.name = this.banner.product[index].title;
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }

  public eventSelectMore() {
    const eventName = 'select_more';
    const eventParams: any = {
      // area_id: this.area.toString(),
      group_type: this.banner.type,
      group_sub_type: this.banner.sub_type,
      group_id: this.banner.id.toString(),
      group_name: this.banner.meta.service_title,
      group_order: this.index.toString()
    }
    firebase.analytics().logEvent(eventName, eventParams);
  }
}
