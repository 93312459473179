














import { Vue, Component, Prop } from 'vue-property-decorator';
import { staticwebModule } from '@/store';

@Component
export default class MainPopup extends Vue {
  @Prop({
    required: true
  })
  public popupState!: boolean;

  @Prop({
    required: true
  })
  public popupContent!: any;

  public todayPopupShow: boolean = false;

  public todayClose() {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    this.$cookies.set('todayPopup', 'Y', date);
    this.$emit('update');
  }
}
