




















































import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, Watch } from 'vue-property-decorator';
import { bannerModule } from '@/store';
import { GPSArea } from '@/store/banner/types';
import { EventBus } from '@/config/config';
import * as firebase from 'firebase/app';

@Component
export default class SelectArea extends Vue {
  public splitterModel: number = 108;
  public tab: string = '서울';
  public area: any = null;

  get areaData() {
    return bannerModule.getArea;
  }

  get banner(): any {
    return bannerModule.getBanner;
  }

  public modalOpened() {
    if (!this.area || this.$cookies.get('areaInfo')) {
      this.area = this.$cookies.get('areaInfo') ? this.$cookies.get('areaInfo') : this.areaData ? this.areaData.default_area : null;
    }
    document.querySelector('html' as any).classList.add('open-modal');
    const areaList: any = this.areaData ? this.areaData.area : [];
    for (let i = 0; i < areaList.length; i++) {
      areaList[i].child.map((x) => {
        if (x.id === this.area.id || x.id === this.area.area_id) {
          this.tab = areaList[i].title;
        }
      });
    }
  }
  public modalClosed() {
    document.querySelector('html' as any).classList.remove('open-modal');
  }
  public destroyed() {
    document.querySelector('html' as any).classList.remove('open-modal');
  }

  public submitArea() {
    this.$emit('update', this.area);
    this.$modal.hide('area');
    this.eventSelectArea();
  }

  public selectArea(item: any) {
    this.area = item;
  }

  public eventSelectArea() {
    const eventName = 'select_area';
    const eventParams: any = {
      type: 'select',
      id: this.area.id,
      name: this.area.display_title,
      banner_group_count: this.banner.length
    };
    firebase.analytics().logEvent(eventName, eventParams);
  }

  @Watch('areaData')
  public setDefaultArea() {
    // this.area = this.areaData ? this.areaData.default_area : null;
  }
}
