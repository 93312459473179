


















import { Vue, Component, Prop } from 'vue-property-decorator';
import * as firebase from 'firebase';
import { EventBus } from '@/config/config';
import { bannerModule } from '@/store';

@Component
export default class AppDownload extends Vue {
  public mounted() {
  }

  public moveAppStore() {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    if (!this.$cookies.get('todayAppDownload')) {
      this.$cookies.set('todayAppDownload', 'Y', date);
    }
    window.location.href = 'https://poingapp.page.link/installApps';
    this.eventAppDownChoice('yes');
    this.$emit('update');
  }

  public continueWeb() {
    const date = new Date();
    date.setHours(23, 59, 59, 999);
    if (!this.$cookies.get('todayAppDownload')) {
      this.$cookies.set('todayAppDownload', 'Y', date);
    }
    this.eventAppDownChoice('no');
    this.$emit('update');
    bannerModule.appDownloadClickUpdate();
  }

  public eventAppDownChoice(data) {
    const eventName = 'app_download';
    let platform: string = '';
    if (this.$q.platform.is.platform === 'android') {
      platform = 'ANDROID';
    } else if (
      this.$q.platform.is.platform === 'iphone' ||
      this.$q.platform.is.platform === 'ios' ||
      this.$q.platform.is.platform === 'ipad'
    ) {
      platform = 'IOS';
    }
    const eventParams: any = {
      choice: data,
      platform: platform
    };
    firebase.analytics().logEvent(eventName, eventParams);
  }
}
