





























import { Vue, Component, Watch } from 'vue-property-decorator';
import { bannerModule, staticwebModule } from '@/store';
import horizontal from '@/components/main/Horizontal.vue';
import BannerList from '@/components/list/BannerList.vue';
import SelectArea from '@/components/main/SelectArea.vue';
import MainPopup from '@/pages/main/MainPopup.vue';
import AppDownload from '@/components/main/AppDownload.vue';
import { EventBus } from '@/config/config';
import { Notify } from 'quasar';
import * as firebase from 'firebase/app';
import { map } from 'lodash';

Component.registerHooks([
  'beforeRouteEnter',
  'beforeRouteLeave',
  'beforeRouteUpdate'
]);

@Component({
  components: {
    horizontal,
    BannerList,
    SelectArea,
    MainPopup,
    AppDownload,
    'spinner': () => import('@/components/layout/Spinner.vue'),
  }
})
export default class Main extends Vue {
  public geoOptions: any = {
    enableHighAccuracy: true,
    maximumAge: 0,
    timeout: Infinity
  };
  public params = {
    area_id: this.$cookies.get('areaInfo') ? this.$cookies.get('areaInfo').area_id : null,
    geo_location: localStorage.nowLocation ? localStorage.nowLocation : ''
  };
  public nowLocation: any = null;
  public todayPopupShow: boolean = false;
  public loadingStatus: boolean = false;
  public myLocationClick: boolean = false;
  public todayAppDownload: boolean = this.$cookies.get('todayAppDownload') === 'Y' ? false : true;
  public dataLoading: boolean = false;

  get banner(): any {
    return bannerModule.getBanner;
  }

  get visited() {
    if (this.$cookies.get('poingVisited')) {
      const re = JSON.parse(this.$cookies.get('poingVisited'));
      const params = {
        ids: map(re, (i) => i.place_id).join(),
        is_schedule: 0
      }
      return params;
      // return map(re, (i) => i.place_id).join();
    }
  }

  public beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.loadingEnd();
      if (!vm.banner) {
        vm.dataLoading = true;
        bannerModule.fetchArea().then((res: any) => {
          if (!vm.$cookies.get('areaInfo')) {
            vm.params.area_id = res.data.default_area.id;
          }
          bannerModule.fetchBanner(vm.params)
          .finally(() => {
            vm.dataLoading = false;
          });
        });

        staticwebModule.fetchMainPopup().then((res) => {
          if (vm.popupContent !== null && vm.popupContent.url && vm.$cookies.get('todayPopup') !== 'Y' && !vm.todayAppDownload) {
            vm.todayPopupShow = true;
            staticwebModule.fetchStaticweb(res.data.staticweb_id).then((staticInfo: any) => {
              vm.adEvent(staticInfo.title);
            })
            vm.$route.params.ad_id = vm.popupContent.id;
          }
        });
      }
      if (vm.visited && !vm.visitedList) {
        bannerModule.fetchVisitedPlace(vm.visited);
      }
      if (vm.$q.platform.is.desktop) {
        vm.todayAppDownload = false;
      }

    })
  }
  public loadingEnd() {
    EventBus.$emit('loadingStatus', false);
    this.loadingStatus = false;
  }

  public mounted() {
    EventBus.$on('updateLocation', (data) => {
      console.log('mounted');
      this.$cookies.set('areaInfo', data, '30min');
      this.nowLocation = data;
      this.params.area_id = data.area_id;
      bannerModule.fetchBanner(this.params);
      Notify.create({
        message: `'${data.service_title}' (으)로 설정되었습니다.`
      });
      this.myLocationClick = !this.myLocationClick;
    });
    // this.getLocation();
    // 전면 광고 팝업
    // staticwebModule.fetchMainPopup();
    if (this.todayPopupShow) {
      document.querySelector('html' as any).classList.add('open-modal');
    } else {
      document.querySelector('html' as any).classList.remove('open-modal');
    }
    // 홈 화면 처음 진입시 현재위치로 지역 조회
    if (!this.$cookies.get('areaInfo')) {
      EventBus.$emit('inHome');
    }
    // firebase.analytics().logEvent('가가가가');
  }

  public adEvent(static_title: any) {
      const eventName = 'show_popup_ad';
      const eventParams: any = {
        id: null,
        static_id: null,
        static_title: null,
      }
      eventParams.id = Number(this.popupContent.id);
      eventParams.static_id = Number(this.popupContent.staticweb_id);
      eventParams.static_title = static_title;
      console.log('전면광고 수집');
      firebase.analytics().logEvent(eventName, eventParams);
    }

  @Watch('todayPopupShow')
  public popupShowHide() {
    if (this.todayPopupShow) {
      document.querySelector('html' as any).classList.add('open-modal');
    } else {
      document.querySelector('html' as any).classList.remove('open-modal');
    }
  }

  @Watch('todayAppDownload')
  public appPopupShowHide() {
    if (this.popupContent !== null && this.popupContent.url && this.$cookies.get('todayPopup') !== 'Y' && !this.todayAppDownload) {
      this.todayPopupShow = true;
    }
  }

  // public getLocation() {
  //   if (navigator.geolocation) {
  //     // navigator.geolocation.getCurrentPosition(this.geoPosition);
  //     navigator.geolocation.getCurrentPosition(this.geoPosition, this.geoError, this.geoOptions);
  //   } else {
  //     alert('GPS를 지원하지 않습니다.');
  //   }
  // }

  public geoPosition(position) {
    localStorage.nowLocation = position.coords.latitude + ',' + position.coords.longitude;
    // 처음 동의했을때 실행
    if (!this.$cookies.get('areaInfo')) {
      EventBus.$emit('inHome');
    }
  }
  // pc : 사파리, 아이폰 : 브라우저 에서 오류로 인해 적용 안함
  public geoError(error) {
    localStorage.removeItem('nowLocation');
    console.log('위치 정보 거절');
  }

  public changeArea(v) {
    const info = {
      area_id: v.id,
      parent_id: v.parent_id,
      service_title: v.display_title
    };
    this.$cookies.set('areaInfo', info, '30min');
    this.params.area_id = v.id;
    EventBus.$emit('updateName', v.display_title);
    console.log('changeArea');
    bannerModule.fetchBanner(this.params);
    Notify.create({
      message: `'${v.display_title}'(으)로 설정되었습니다.`
    });
  }
  public isWebShowHide(banner: any): boolean {
    let list: any = banner;
    let type: any = banner.type;
    if (
      type === 'slide' ||
      type === 'static' ||
      type === 'list'
    ) {
      list = list.banner;
    } else if (
      type === 'place'
    ) {
      if (list.sub_type === 'interest') {
        list = this.visitedList;
      } else {
        list = list.place;
      }
    } else if (
      type === 'product'
    ) {
      list = list.product;
    }
    if (list && list.length) {
      const re = list.filter((row: any) => {
        if (
          type === 'slide' ||
          type === 'static' ||
          type === 'list'
        ) {
          return row.image && row.image.banner_web_image && row.image.banner_web_image.service && row.image.banner_web_image.service.url;
        } else {
          return row.image && row.image.service && row.image.service.url;
        }
      });
      if (re && re.length) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get popupContent() {
    return staticwebModule.mainPopup;
  }

  get visitedList(): any {
    return bannerModule.visitedList;
  }
}
